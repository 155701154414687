import { AuthenticationParameters, Configuration } from "msal";


const getAppClientId = () => {
    if (window.location.origin.includes('localhost')) return 'd86de5a2-a44f-4ed1-a3e9-b21decfdef53';
    else if (window.location.origin.includes('-dev')) return 'd86de5a2-a44f-4ed1-a3e9-b21decfdef53';
    else if (window.location.origin.includes('-test')) return '2ea24086-0d6f-4c8d-88e2-529f89e89627';
    else return 'b9410ba6-50ab-4740-a256-4270e72fcd47';
}

export const scopes = {
    UserImpersonation: `api://${getAppClientId()}/user_impersonation`,
}


export const msalConfig: Configuration = {
    auth: {
      clientId: getAppClientId(),
      authority: "https://login.microsoftonline.com/2e114308-14ec-4d77-b610-490324fa1844",
      redirectUri: `${window.location.protocol}//${window.location.host}/`,
    }
  };


// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const tokenRequest: AuthenticationParameters = {
    scopes: [scopes.UserImpersonation]
};
