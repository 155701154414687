import ILanguage from "../Interfaces/ILanguage"

export const en: ILanguage = {
    appName: "Delivery Proof",
    signingIn: "Signing In..",
    startPage: {
        headers: {
            welcomeText: "Welcome to Beijer Delivery Proof, use the Search box in the topbar to search for an order",
        }
    },
    orderPage: {
        loadingOrder: "Loading order",
        noDeliveryEvents: "No delivery events found",
        noImagesFound: "No images found",

        dataFieldLabels: {
            customerName: "Customer Name",
            deliveryName: "Delivery Name",
            custNo: "Cust No",
            truckId: "Truck Id",
            locationAddres: "Location Addres",
            locationCity: "Location City",
            locationPostalCode: "Location Postal Code",
            locationState: "Location State",
        },
        actions: {
            viewAsPrintable: "View as Printable",
        },
        headers: {
            events: "Events",
            additonalOrders: "Other orders in the delivery",
            deliveryInfo: "Delivery Information",
            noOrderWithIdFound: (text: string) => `No order with the id '${text}' could be found.`,
            images: "Images",
            noImages: "No images uploaded.."
        },
        event: {
            driver: 'Driver',
            route: 'Route',
            eventCMD: (cmd: number) => {
                switch (cmd) {
                    case 7: return 'Arrive';
                    case 8: return 'Complete';
                    case 35: return 'Photo';
                    default: return '';
                }
            },
            eventSType: (stype: number) => {
                switch (stype) {
                    case 0: return 'Start Depot';
                    case 1: return 'End Depot';
                    case 2: return 'Pickup';
                    case 3: return 'Delivery';
                    default: return '';
                }
            },
        },
        image: {
            invalidImage: 'Invalid image',
            imageTypeName: (type: string) => {
                switch (type) {
                    case "Job Signature": return 'Signature';
                    case "Job Photo": return 'Delivery';
                    default: return '';
                }
            },
        },
    },
    orderNotFoundPage: {
        noOrderWithIdFound: (text: string) => `No order with the id '${text}' could be found.`,
    }
}