import { Alert, Button, Input } from 'antd';
import React, { ReactElement } from 'react';
import './AppCommandBar.scss';
import 'antd/dist/antd.min.css';
import { IAction } from '../../Interfaces/IAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons'



interface IAppCommandBar {
  actions: IAction[]
};

const AppCommandBarButton: React.FC<IAction> = ({ icon, text, onClick, disabled }): ReactElement => (
  <div
    className={`app-commandbar-button ${disabled ? 'disabled' : 'enabled' }`}
    onClick={onClick}
  >
    <FontAwesomeIcon size="1x" icon={icon ? icon : faPlay} />
    <span>{text}</span>
  </div>
);

const AppCommandBar: React.FC<IAppCommandBar> = ({ actions }): ReactElement => (
  <div className="app-commandbar">
      {
        actions && actions.map(_ => {
          if(_.hide) return null;
          return (
            <AppCommandBarButton {..._}
            >
              { _.text }
            </AppCommandBarButton>
          )
        })
      }
    </div>
);

export default AppCommandBar;
