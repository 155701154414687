import { Button, Spin } from 'antd';
import { Account } from 'msal';
import React, { ReactElement, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import UserAPI from '../API/UserAPI';
import { LanguageAtom } from '../Atoms/LanguageAtom';
import { Languages } from '../Lang/languages';
import { ApplicationUser } from '../Types/ResponseTypes/ApplicationUser';
import AppHeader from '../Components/AppHeader/AppHeader';
import './App.scss';
import { AppRoute, routes } from './Routes';

import { faEarthEurope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthService from '../Services/AuthService';


export interface IApp {
}

const App: React.FC<IApp> = ({ }): ReactElement => {
  const [language, setLanguage] = useRecoilState(LanguageAtom);

  useEffect(() => {
    const languagePreferens = localStorage.getItem('language-preferens');
    if (languagePreferens) setLanguage(Languages[languagePreferens]);
  }, []);

  const setLanguageSwedish = () => {
    setLanguage(Languages['sv']);
    localStorage.setItem('language-preferens', 'sv');
  };
  const setLanguageEnglish = () => {
    setLanguage(Languages['en']);
    localStorage.setItem('language-preferens', 'en');
  };

  const [userAccount, setUserAccount] = useState<Account>();
  const [user, setUser] = useState<ApplicationUser>();

   useEffect(() => {
    const acc = AuthService.getCurrent().login();
    if (acc) setUserAccount(acc);
  }, []);



  if (!userAccount) {
    return (
      <div id="signing-in-wrapper">
        <div id="signing-in-logo"><span>{language.appName}</span></div>
        <Spin tip={language.signingIn} size="large" />
      </div>
    )
  }


  return (
    <div id="app">

      <BrowserRouter>
        <AppHeader />
        <div id="app-page-wrapper">
          <Routes>
            {
              routes.map((route: AppRoute, index: number): ReactElement => {
                const key = `route-${index}`;
                return (
                  <Route key={key} path={route.path} element={<route.component />} />
                );
              })
            }
          </Routes>
        </div>
        <div id="app-language-selector">

          <Button
            type="text"
            onClick={setLanguageEnglish}
          >English</Button>
          <span>|</span>
          <Button
            type="text"
            onClick={setLanguageSwedish}
          >Svenska</Button>
          <FontAwesomeIcon size="1x" icon={faEarthEurope} title="Language" />


        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;