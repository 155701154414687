import { mockOrder } from '../MockData/mock-order';
import { httpRequestWithMock } from '../Services/HttpService';
import { Order } from '../Types/ResponseTypes/Order';
import { OrderGrouped } from '../Types/ResponseTypes/OrderGrouped';

const OrderAPI = {
  getOrderById: (id: string) => httpRequestWithMock<Order>('GET', `/v2/orders/${id}`, mockOrder),
  //getOrderGroupedById: (id: string) => httpRequestWithMock<OrderGrouped>('GET', `/v1/orders/${id}/grouped`, mockOrderGrouped),

  getOrderImageById: (imageId: number) => httpRequestWithMock<string>('GET', `/v2/orders/images/${imageId}`, ""),
};

export default OrderAPI;
