import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { formatDateString } from '../../../../Services/DateService';
import { Delivery, Order } from '../../../../Types/ResponseTypes/Order';
import PrintImage from './PrintImage';
import './PrintImages.scss';


interface IPrintImages {
  order: Order,
};

const css = `
.center-text {
  text-align: center;
}

#app-print-header {
  display: grid;

  height: 60px;

  grid-template-columns: 1fr 200px 1fr;
  grid-template-rows: 1fr;

  width: 750px;
  margin: 0px auto;
  padding: 20px 30px;
  border-bottom: 2px solid;
}

#app-logo {
  height: 50px;
}

#app-print-header-logo {
  grid-row: 1;
  grid-column: 1;
}

#app-print-header-title {
  grid-row: 1;
  grid-column: 2;

  font-size: 22px;
  font-weight: 600;

  text-align: center;
  line-height: 50px;
}

#app-print-header-searchbar {
  grid-row: 1;
  grid-column: 3;
}

.app-print-image {
  padding: 20px;
  border-bottom: 1px dashed lightgray;
  
}

.app-print-image .app-print-image-type {
  font-size: 22px;
  padding-left: 5px;
  float: left;
}

.app-print-image .app-print-image-created {
  font-size: 22px;
  padding-right: 5px;
  float: right;
}

.app-print-image div.app-print-image-placeholder {
  height: 200px;
  width: 300px;

  border: 1px dashed grey;

  text-align: center;
  line-height: 200px;

  
}

.app-print-image div.app-print-image-placeholder > svg {
  margin-top: 58px;
  color: lightgrey;
}

body {
  background: grey;
}

.app-print-images {
  background: white;
  width: 842px;
  margin: 0px auto;
}

.app-print-images-wrapper {

  padding: 20px 30px;
}

`;


const PrintImages: React.FC<IPrintImages> = ({ order }): ReactElement => {

  return (
    <div className="app-print-images">
      <style>{css}</style>
      <div id="app-print-header">
        <div id="app-print-header-logo">
          <a href={window.location.origin}>
            <img src="/assets/img/beijer-logo.svg" alt="logo" id="app-logo" />
          </a>
        </div>
        <div id="app-print-header-title">Beijer Leveransbevis</div>
      </div>

      <h1 className="center-text">{order.orderId}</h1>
      {
        order.deliveries
          ? order.deliveries.map(delivery => (
            <div className="app-print-images-wrapper">
              {
                delivery
                  ? delivery.images.map(_ => (
                    <div className="app-print-images-wrapper">
                      <PrintImage deliveryImage={_} />
                    </div>
                  )) : null
              }
            </div>
          )) : null
      }

    </div>
  )
};

export default PrintImages;
