import ILanguage from "../Interfaces/ILanguage"

export const sv: ILanguage = {
    appName: "Leveransbevis",
    signingIn: "Loggar in..",
    startPage: {
        headers : { 
            welcomeText: "Välkommen till Beijer Leveransbevis, använd sökrutan i högst upp på sidan för att söka på en order",
        }
    },
    orderPage: {
        loadingOrder: "Laddar order",
        noDeliveryEvents: "Inga leverans händelser hittades",
        noImagesFound: "Inga bilder hittades",

        dataFieldLabels: {
            customerName: "Namnförtydligande",
            deliveryName: "Leverans Namn",
            custNo: "Stoppdata (KundNr, Address, Aviseringstelefon)",
            truckId: "Lastbils Id",
            locationAddres: "Leveransadress",
            locationCity: "Leveransens Postadress",
            locationPostalCode: "Leveransens PostNummer",
            locationState: "Län",
        },
        actions: {
            viewAsPrintable: "Visa som Utskrivbar",
        },
        headers: {
            events: "Händelser",
            additonalOrders: "Ordrar i Leverans",
            deliveryInfo: "Leverans Information",
            noOrderWithIdFound: (text: string) => `Ingen order med id't '${text}' kunde hittas.`,
            images: "Bilder",
            noImages: "Inga bilder uppladdade.."
        },
        event: {
            driver: 'Förare',
            route: 'Rutt',
            eventCMD: (cmd: number) => {
                switch (cmd) {
                    case 7: return 'Anlänt';
                    case 8: return 'Färdig';
                    case 35: return 'Bild';
                    default: return '';
                }
            },
            eventSType: (stype: number) => {
                switch (stype) {
                    case 0: return 'Start Depå';
                    case 1: return 'Slut Depå';
                    case 2: return 'Pickup';
                    case 3: return 'Leverans';
                    default: return '';
                }
            },
        },
        image: {
            invalidImage: 'Ogiltlig bild',
            imageTypeName: (type: string) => {
                switch (type) {
                    case "Job Signature": return 'Signatur';
                    case "Job Photo": return 'Leverans';
                    default: return '';
                }
            },
        },
    },
    orderNotFoundPage: {
        noOrderWithIdFound: (text: string) => `Ingen order med id't '${text}' kunde hittas.`,
    }
}