import { Alert } from 'antd';
import React, { ReactElement } from 'react';
import './StatusCodes.scss';

interface IAccessDenied {
  message?: string
};

const AccessDenied: React.FC<IAccessDenied> = ({ message }): ReactElement => (
  <div className="app-status-code">
    {message && (
      <Alert
        message={`Login failed, login service returned: '${message}'`}
        type="error"
        showIcon
      />
    )}
    <div>
      <span>4</span>
      <span>0</span>
      <span>3</span>
    </div>
    <h3>
      You do not have access to this page/application.
    </h3>
  </div>
);

export default AccessDenied;
