import { Spin } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { LanguageAtom } from '../../Atoms/LanguageAtom';
import AccessDenied from '../../Components/StatusCodes/AccessDenied';
import PageNotFound from '../../Components/StatusCodes/PageNotFound';
import IPage from '../../Interfaces/IPage';

interface IShortHandleOrderPage extends IPage {

};

const ShortHandleOrderPage: React.FunctionComponent<IShortHandleOrderPage> = () => {
  const [language] = useRecoilState(LanguageAtom);
  const { orderId } = useParams<{ orderId?: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if(orderId) navigate(`/orders/${orderId}`);
  }, [orderId])
  

  return (
    <div className="app-page-content">
      <Spin tip={language.orderPage.loadingOrder} />
    </div>
  );
};

export default ShortHandleOrderPage;
