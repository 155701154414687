import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import OrderAPI from '../../../../API/OrderAPI';
import { useDataWithParams } from '../../../../hooks/use-data';
import { formatDateString } from '../../../../Services/DateService';
import { Delivery, DeliveryImage, Order } from '../../../../Types/ResponseTypes/Order';


interface IPrintImage {
  deliveryImage: DeliveryImage,
};


const PrintImage: React.FC<IPrintImage> = ({ deliveryImage }): ReactElement => {

  const {
    data,
    loading,
    error
  } = useDataWithParams(OrderAPI.getOrderImageById, deliveryImage.imageId);
  
  return (
    <div className="app-print-image">
      <div>
        <span className="app-print-image-type">{deliveryImage.imageType}</span>
        <span className="app-print-image-created">{formatDateString(deliveryImage.systemCreateTime)}</span>
      </div>
      {
        data && !data.isError
          ? (<img src={`data:image/jpeg;base64, ${data.result}`} alt="" width={680} />)
          : (
            <div className="app-print-image-placeholder">
              <FontAwesomeIcon size="6x" icon={faImage} />
            </div>
          )
      }
  
  
    </div>
  
  )
  
};

export default PrintImage;
