import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'antd';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import './StatusCodes.scss';

interface IPageNotFound {
  message?: string
};

const PageNotFound: React.FC<IPageNotFound> = ({ message }): ReactElement => (
  <div className="app-status-code-wrapper">
    {message && (
      <Alert
        message={`Login failed, login service returned: '${message}`}
        type="error"
        showIcon
      />
    )}
    <div className="app-status-code">
      <h1>404</h1>
      <p>
        This page doesn't appear to exists, did Loki prank you again?
      </p>
    </div>
    <div className="app-status-code-links">
      <h3>
        Looking for..?
      </h3>
      <div>
        <span className="app-status-code-link-icon"><FontAwesomeIcon icon={faArrowRight} /></span><Link to="#">Link to ABC</Link>
      </div>
      <div>
        <span className="app-status-code-link-icon"><FontAwesomeIcon icon={faArrowRight} /></span><Link to="#">Link to ABC</Link>
      </div>
      <div>
        <span className="app-status-code-link-icon"><FontAwesomeIcon icon={faArrowRight} /></span><Link to="#">Link to ABC</Link>
      </div>
    </div>
  </div>
);

export default PageNotFound;
