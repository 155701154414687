import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AccessDenied from '../../Components/StatusCodes/AccessDenied';
import PageNotFound from '../../Components/StatusCodes/PageNotFound';
import IPage from '../../Interfaces/IPage';
import './OrderNotFoundPage.scss';

interface IOrderNotFoundPage extends IPage {
};


interface INotFoundLocation {
  searchTerm: string,
};

const OrderNotFoundPage: React.FunctionComponent<IOrderNotFoundPage> = ({ applicationUser }) => {
  const location = useLocation();
  const state = location.state as INotFoundLocation;

  return (
    <div className="app-page-content center">
      <h3>Cannot find any order with Id '{state?.searchTerm}'.</h3>
    </div>
  );
};

export default OrderNotFoundPage;
