import { Alert, Input } from 'antd';
import React, { ChangeEventHandler, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import OrderAPI from '../../API/OrderAPI';
import { LanguageAtom } from '../../Atoms/LanguageAtom';
import './AppHeader.scss';

const { Search } = Input;


interface IAppHeader {

};

const AppHeader: React.FC<IAppHeader> = ({ }): ReactElement => {
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  
  const [language] = useRecoilState(LanguageAtom);

  const navigate = useNavigate();
  const onSearch = () => {
    setSearching(true);
  };

  useEffect(() => {
    if (searching)
      OrderAPI.getOrderById(searchTerm)
        .then(_ => {
          navigate(`/orders/${_.result.orderId}${window.location.search.includes("useMockData=true") ? '?useMockData=true' : ''}`);
          setSearchTerm('');
          setSearching(false);
        })
        .catch(_ => {
          navigate(`/orders/not-found${window.location.search.includes("useMockData=true") ? '?useMockData=true' : ''}`, { state: { searchTerm: searchTerm } });
          setSearching(false);
        })
  }, [searching])


  return (
    <div id="app-header">
      <div id="app-header-logo">
        <a href={window.location.origin}>
          <img src="/assets/img/beijer-logo.svg" alt="logo" id="app-logo" />
        </a>
      </div>
      <div id="app-header-title">{language.appName}</div>
      <div id="app-header-searchbar">
        <Search value={searchTerm} loading={searching} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search" style={{ width: '260px', float: 'right', margin: '9px 20px' }} onSearch={onSearch} />
      </div>
    </div>
  );
}

export default AppHeader;
