import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { AppException, UnknownError } from "../Models/Exceptions";

export const useDataWithParams = <TData, TParam>(
  dataFetcher: (param: TParam) => Promise<TData>,
  param: TParam,
  options: {
    initialValue: TData | undefined,
  } = {
      initialValue: undefined
    }
): {
  data: TData | undefined,
  loading: boolean,
  error: AppException | undefined
  refreshData: () => void,
} => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [internalParam, setInternalParam] = useState<TParam>(param);
  const [state, setState] = useState<TData>();
  const [error, setError] = useState<AppException>();

  useEffect(() => {
    let isCanceled = false;

    if (fetching) {
      dataFetcher(internalParam)
        .then(_ => {
          if (!isCanceled) setState(_);
          setFetching(false);
        })
        .catch(_ => {
          setError(_);
          setFetching(false);
        });
    }

    return () => {
      if (fetching) isCanceled = true;
    };
  }, [fetching]);

  useEffect(() => {
    if(!fetching) setFetching(true);
  }, [internalParam])

  useEffect(() => {
    const paramIsEqual = JSON.stringify(param) === JSON.stringify(internalParam);
    if(!paramIsEqual) setInternalParam(param);
  }, [param])


  useEffect(() => {
    setFetching(true);
  }, [])

  const refreshData = () => setFetching(true);

  return {
    data: state,
    loading: fetching,
    error: error,
    refreshData: refreshData,
  };
};

export const useData = <TData>(
  dataFetcher: () => Promise<TData>,
  options: {
    initialValue: TData | undefined,
  } = {
      initialValue: undefined
    }
): {
  data: TData | undefined,
  loading: boolean,
  error: AppException | undefined,
  refreshData: () => void,
} => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [state, setState] = useState<TData>();
  const [error, setError] = useState<AppException>();

  useEffect(() => {
    let isCanceled = false;

    if (fetching) {
      dataFetcher()
        .then(_ => {
          if (!isCanceled) setState(_);
          setFetching(false);
        })
        .catch(_ => {
          setError(_);
          setFetching(false);
        });
    }

    return () => {
      if (fetching) isCanceled = true;
    };
  }, [fetching]);

  useEffect(() => {
    setFetching(true);
  }, [])

  const refreshData = () => setFetching(true);

  return {
    data: state,
    loading: fetching,
    error: error,
    refreshData,
  };
};

