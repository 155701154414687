import React from 'react';
import DefaultPage from '../Pages/DefaultPage/DefaultPage';
import OrderNotFoundPage from '../Pages/OrderNotFoundPage/OrderNotFoundPage';
import ShortHandleOrderPage from '../Pages/ShortHandleOrderPage/ShortHandleOrderPage';
import OrderPage from '../Pages/OrderPage/OrderPage';
import StartPage from '../Pages/StartPage/StartPage';

export interface AppRoute {
  path: string,
  component: React.FC,
};

export const routes: AppRoute[] = [
  {
    path: 'orders/not-found',
    component: OrderNotFoundPage,
  },
  {
    path: 'orders/:orderId',
    component: OrderPage,
  },
  {
    path: 'o/:orderId',
    component: ShortHandleOrderPage,
  },
  {
    path: '/',
    component: StartPage,
  },
  {
    path: '*',
    component: DefaultPage,
  }

];
