import { Alert, Input, Skeleton, Timeline } from 'antd';
import React, { ReactElement } from 'react';
import { useRecoilState } from 'recoil';
import { LanguageAtom } from '../../../../Atoms/LanguageAtom';
import { formatDateString } from '../../../../Services/DateService';
import { OrderEvent } from '../../../../Types/ResponseTypes/Order';
import './Event.scss';
import { cmdToText, sTypeToText } from './EventTypeLookup';

interface IEvent {
  event: OrderEvent,
};

const Event: React.FC<IEvent> = ({ event }): ReactElement => {
  const [language] = useRecoilState(LanguageAtom);
  const { orderPage: {
    event: {
      eventCMD, eventSType, driver, route
    }
  } } = language;

  return (
    <div className="app-order-event">

      <div className="app-order-event-info">
        <h4>{formatDateString(event.ltstamp)} - {eventCMD(event.cmd)}, {eventSType(event.stype)}</h4>
        <div className="app-order-event-info-field">
          <span>{driver}:</span>
          <span>{event.driverName}</span>
        </div>
        <div className="app-order-event-info-field">
          <span>{route}:</span>
          <span>{event.routeName}</span>
        </div>
      </div>


    </div>
  );
}
export default Event;
