import { Alert, Descriptions, Divider, Input, Skeleton, Timeline } from 'antd';
import React, { ReactElement } from 'react';
import { useRecoilState } from 'recoil';
import { LanguageAtom } from '../../../../Atoms/LanguageAtom';
import ILanguage from '../../../../Interfaces/ILanguage';
import { formatDateString } from '../../../../Services/DateService';
import { Delivery, OrderEvent, SubOrder } from '../../../../Types/ResponseTypes/Order';
import Event from '../Event/Event';
import EventImage from '../Image/Image';
import './Event.scss';
import { cmdToText, sTypeToText } from './EventTypeLookup';

interface IDeliveryView {
  delivery: Delivery,
};

const DeliveryView: React.FC<IDeliveryView> = ({ delivery }): ReactElement => {
  const [language] = useRecoilState<ILanguage>(LanguageAtom);

  return (
    <div className="app-order-sub-order">

      <div id="app-order-data">
        <div id="app-order-orderEvents">
          <Divider orientation="left">{language.orderPage.headers.events}</Divider>
          <Timeline mode={"left"}>
            {
              // Filter: Only show delivery orderEvents thats not picture uploads
              delivery && delivery.orderEvents
                ? delivery.orderEvents.filter(_ => !(_.stype == 3 && _.cmd == 8) && _.cmd != 35).sort((a, b) => a?.tstamp?.localeCompare(b?.tstamp) * -1).map((_, i) => (
                  <Timeline.Item>
                    <Event event={_} />
                  </Timeline.Item>
                ))
                : (<p>{language.orderPage.noDeliveryEvents}</p>)
            }
          </Timeline>
        </div>

        <div className="app-order-images">
          <Divider orientation="left">{language.orderPage.headers.images}</Divider>

          {
              delivery && delivery.subOrders && delivery.subOrders.length > 0
                ? <div><p>{language.orderPage.headers.additonalOrders}: {delivery.subOrders.map(_ => _.subOrderId)?.join(', ')}</p> </div>
                : null
            }
          <div className="app-order-imagegroup">
            {
              delivery.images && delivery.images.length > 0
                ? delivery.images.sort((a, b) => a.imageType.localeCompare(b.imageType) * -1).map(_ => (

                  <EventImage image={_} />
                )) : (<p>{language.orderPage.headers.noImages}</p>)
            }
          </div>
          {
            delivery && delivery.hasDeliveryStartedEvent ? (

              <>
                <Divider orientation="left">{language.orderPage.headers.deliveryInfo}</Divider>
                <Descriptions
                  size='small'
                  bordered
                  column={2}
                >
                  <Descriptions.Item label={language.orderPage.dataFieldLabels.customerName}>{delivery.customerName}</Descriptions.Item>
                  <Descriptions.Item label={language.orderPage.dataFieldLabels.deliveryName}>{delivery.deliveryName}</Descriptions.Item>

                  <Descriptions.Item label={language.orderPage.dataFieldLabels.custNo}>{delivery.custNo}</Descriptions.Item>
                  <Descriptions.Item label={language.orderPage.dataFieldLabels.truckId}>{delivery.truckId}</Descriptions.Item>

                  <Descriptions.Item span={2} label={language.orderPage.dataFieldLabels.locationAddres}>{delivery.locationAddres}</Descriptions.Item>

                  <Descriptions.Item label={language.orderPage.dataFieldLabels.locationPostalCode}>{delivery.locationPostalCode}</Descriptions.Item>
                  <Descriptions.Item label={language.orderPage.dataFieldLabels.locationCity}>{delivery.locationCity}</Descriptions.Item>

                </Descriptions>
              </>
            ) : null
          }
        </div>
      </div>

    </div>
  );
}
export default DeliveryView;
