import { faImage, faBug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image as AntdImage, Input } from 'antd';
import { useRecoilState } from 'recoil';
import { LanguageAtom } from '../../../../Atoms/LanguageAtom';
import React, { ReactElement, useState, useEffect } from 'react';
import { formatDateString } from '../../../../Services/DateService';
import { DeliveryImage, OrderImage } from '../../../../Types/ResponseTypes/Order';
import './Image.scss';
import OrderAPI from '../../../../API/OrderAPI';
import { useDataWithParams } from '../../../../hooks/use-data';

const getImageType = (char: string) => {
  switch (char) {
    case '/': return 'jpeg';
    case 'i': return 'png';
    case 'U': return 'webp';
    case 'R': return 'gif';
    default: return 'jpeg';
  }
}

interface IEventImage {
  image: DeliveryImage,
};

const renderImage = (image: DeliveryImage, imageData: string | undefined, imageDataType: string, isValidImage: boolean) => {
  const [language] = useRecoilState(LanguageAtom);
  
  if (imageData && isValidImage) return (
    <AntdImage
      className="app-order-image"
      src={`data:image/${imageDataType};base64,${imageData}`}
      alt={language.orderPage.image.imageTypeName(image.imageType)}
    />
  )
  else if (imageData && !isValidImage) return (
    <div className="app-order-image-placeholder">
      <FontAwesomeIcon size="6x" icon={faBug} />
      <p>{language.orderPage.image.invalidImage}</p>
    </div>
  )
  else return (
    <div className="app-order-image-placeholder">
      <FontAwesomeIcon size="6x" icon={faImage} title={language.orderPage.image.invalidImage} />
    </div>
  )

}

const EventImage: React.FC<IEventImage> = ({ image }): ReactElement => {
  const [language] = useRecoilState(LanguageAtom);
  const [isValidImage, setIsValidImage] = useState(true);
  const [imageDataType, setImageDataType] = useState('jpeg')
  
  const {
    data,
    loading,
    error
  } = useDataWithParams(OrderAPI.getOrderImageById, image.imageId);

  useEffect(() => {
    if (image.imageType && data) {
      const imageType = getImageType(data.result.charAt(0));
      if (imageDataType != imageType) setImageDataType(imageType);
    }
  }, [image])

  useEffect(() => {
    if (!data) return;
    const checkImage = (str: string) => {
      let img = new Image();
      img.src = str;
      img.onerror = (e) => {
        console.log('Image Error:', image, e);
        setIsValidImage(false);
      }
    };
    checkImage(`data:image/${imageDataType};base64,${data?.result}`);
  }, [data, imageDataType])

  return (
    <div className="app-order-image-wrapper">
      <div className="app-order-image">
        {
          renderImage(image, data?.result, imageDataType, isValidImage)
        }

      </div>

      <div>
        <span className="app-image-type">{language.orderPage.image.imageTypeName(image.imageType)}</span>
        <span className="app-image-created">{formatDateString(image.systemCreateTime)}</span>
      </div>
    </div>

  );
}
export default EventImage;
