import React, { ReactElement, useEffect, useState } from 'react';
import AccessDenied from '../../Components/StatusCodes/AccessDenied';
import PageNotFound from '../../Components/StatusCodes/PageNotFound';
import IPage from '../../Interfaces/IPage';
import './DefaultPage.scss';

interface IDefaultPage extends IPage {

};

const DefaultPage: React.FunctionComponent<IDefaultPage> = ({ applicationUser }) => {

  if (!applicationUser) return (
    <div className="app-page-content">
      <AccessDenied />
    </div>
  );

  return (
    <div className="app-page-content">
      <PageNotFound />
    </div>
  );
};

export default DefaultPage;
