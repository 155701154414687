import { UserAgentApplication } from "msal";
import { tokenRequest, msalConfig } from "../API/authConfig";


export default class AuthService {
  static authService: AuthService;
  userApp: UserAgentApplication;

  constructor() {
    this.userApp = new UserAgentApplication(msalConfig);
    this.userApp.handleRedirectCallback(() => false)
  }

  static getCurrent = () => {
    if (!AuthService.authService) {
      AuthService.authService = new AuthService();
    }
    return AuthService.authService;
  }

  login = () => {
    if (window.location.href.indexOf('#id_token') >= 0) {
      return;
    }
    if (sessionStorage.getItem('msal.interaction.status')) {
      return;
    }
    const userAcc = this.userApp.getAccount();
    if (!userAcc) {
      this.userApp.loginRedirect(tokenRequest);
      return;
    } else {
      return userAcc;
    }
  }

  getAccessToken = async () => {
    let token;
    try {
      try {
        token = await this.userApp.acquireTokenSilent(tokenRequest);
      } catch (error) {
        console.warn('Initial AcquireTokenSilent failed: ', error);
        console.log('Attempting AcquireTokenRedirect..');
        token = await this.userApp.acquireTokenRedirect(tokenRequest);
      }
    } catch (error) {
      console.warn('AcquireTokenRedirect failed: ', error);
      console.log('Attempting (PopUp) Login..');
      try {
        await this.userApp.loginPopup();
      } catch (error) {
        console.warn('Initial PopUp Login failed: ', error);
        console.log('Attempting (Redirect) Login..');
        token = await this.userApp.loginRedirect(tokenRequest);
      }

      try {
        token = await this.userApp.acquireTokenSilent(tokenRequest);
      } catch (error) {
        console.warn('Initial AcquireTokenSilent failed: ', error);
        console.log('Attempting AcquireTokenRedirect..');
        token = await this.userApp.acquireTokenRedirect(tokenRequest);
      }
    }
    return token ? token.accessToken : token;
  }

  getAuthHeaders = async () => {
    return {
      'Accept': 'application/json',
      'Authorization': `Bearer ${await this.getAccessToken()}`
    };
  }

};
