import * as ReactDOMClient from 'react-dom/client';
import AppCommandBar from '../../Components/AppCommandBar/AppCommandBar';
import DeliveryView from './Components/Delivery/DeliveryView';
import IPage from '../../Interfaces/IPage';
import OrderAPI from '../../API/OrderAPI';
import PrintImages from './Components/PrintImages/PrintImages';
import React, { useEffect, useState } from 'react';
import { AppException } from '../../Models/Exceptions';
import { Descriptions, Divider, Skeleton, Spin, Timeline } from 'antd';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import { getWindowTitle } from '../../App/variables';
import { LanguageAtom } from '../../Atoms/LanguageAtom';
import { Link, useParams } from 'react-router-dom';
import { Order } from '../../Types/ResponseTypes/Order';
import { useRecoilState } from 'recoil';
import './OrderPage.scss';

interface IOrderPage extends IPage {

};

const OrderPage: React.FunctionComponent<IOrderPage> = (props) => {
  const [error, setError] = useState<AppException>();
  const [order, setOrder] = useState<Order>();
  const [loading, setLoading] = useState<boolean>(true);
  const { orderId } = useParams<{ orderId?: string }>();

  const [language] = useRecoilState(LanguageAtom);

  useEffect(() => {
    document.title = getWindowTitle(`${orderId}`);
    if (orderId != order?.orderId) setLoading(true);
  }, [orderId])

  useEffect(() => {
    if (orderId && loading)
      OrderAPI.getOrderById(orderId)
        .then(_ => {
          setLoading(false);
          setOrder(_.result);
        })
        .catch(_ => {
          setLoading(false);
          setError(_);
        });

  }, [loading])

  if (loading) {
    return (
      <div className="app-page-content">
        <Spin tip={language.orderPage.loadingOrder} />
      </div>
    );
  }

  if (order == null) {
    return (
      <div>
        <p>{language.orderPage.headers.noOrderWithIdFound(orderId || '')}</p>
      </div>
    );
  }

  const print = () => {
    if (order) {
      const tab = window.open("", "_blank");
      tab?.document.write("<div id='root'></div>");
      const rootEle = tab?.document.getElementById("root")
    
      if (rootEle) {
        const root = ReactDOMClient.createRoot(rootEle);
        root.render(<PrintImages order={order} />);
      }
    }
  };

  return (
    <div className="app-page-content">
      <h2>{orderId}</h2>
      <AppCommandBar actions={[
        { text: language.orderPage.actions.viewAsPrintable, onClick: print, icon: faPrint }
      ]} />


      {
        order && order.deliveries
          ? order.deliveries.map(_ =>
            <>
              <Divider orientation="left">{_.subOrderId}</Divider>
              <DeliveryView delivery={_} />
            </>)
          : null
      }

    </div >
  );
};

export default OrderPage;
