export class AppException {
  type: string;
  header: string;
  message: string;

  constructor(header: string, message: string, type: string = "",) {
    this.type = type ? type : 'AppException';
    this.header = header;
    this.message = message;
  }
};


export class BadRequest extends AppException {
  constructor(errorBody: string) {
    super('Bad Request', errorBody, 'BadRequest');

  };
}
export class AccessTokenExpired extends AppException {
  constructor(errorBody: string) {
    super('Access Token Expired', errorBody, 'AccessTokenExpired');

  };
}
export class ForbiddenRequest extends AppException {
  constructor(errorBody: string) {
    super('Forbidden Request', errorBody, 'ForbiddenRequest');

  };
}
export class ResourceNotFound extends AppException {
  constructor(errorBody: string) {
    super('Resource Not Found', errorBody, 'ResourceNotFound');

  };
}
export class ServerError extends AppException {
  constructor(errorBody: string) {
    super('Server Error', errorBody, 'ServerError');

  };
}
export class UnknownError extends AppException {
  constructor(errorBody: string) {
    super('Unknow Error', errorBody, 'UnknownError');

  };
}
